import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>QUALITY ASSURANCE TESTER</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>Proven work experience in software quality assurance</li>
          <li>Strong knowledge of software QA methodologies, tools and processes</li>
          <li>Experience in writing clear, concise and comprehensive test plans and test cases</li>
          <li>Hands-on experience with both white box and black box testing</li>
          <li>Knowledge of automated testing tools for both web and mobile is a plus</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
